import React from 'react';
import './SearchPage.css';

export default function CustomerIcon() {
  return (
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      className="labelIcon"
      data-type="outline"
      data-name="badge"
    >
      <g transform="translate(0, 0)" fill="none">
        <polyline
          fill="none"
          stroke="currentColor"
          vectorEffect="non-scaling-stroke"
          strokeLinecap="square"
          strokeMiterlimit="10"
          points="14,6 23,6 23,23 1,23 1,6 10,6 "
          strokeLinejoin="miter"
        ></polyline>{' '}
        <path
          fill="none"
          stroke="currentColor"
          vectorEffect="non-scaling-stroke"
          strokeLinecap="square"
          strokeMiterlimit="10"
          d="M14,8h-4V3 c0-1.105,0.895-2,2-2h0c1.105,0,2,0.895,2,2V8z"
          strokeLinejoin="miter"
        ></path>{' '}
        <line
          data-color="color-2"
          fill="none"
          stroke="currentColor"
          vectorEffect="non-scaling-stroke"
          strokeLinecap="square"
          strokeMiterlimit="10"
          x1="15"
          y1="13"
          x2="19"
          y2="13"
          strokeLinejoin="miter"
        ></line>{' '}
        <line
          data-color="color-2"
          fill="none"
          stroke="currentColor"
          vectorEffect="non-scaling-stroke"
          strokeLinecap="square"
          strokeMiterlimit="10"
          x1="15"
          y1="17"
          x2="19"
          y2="17"
          strokeLinejoin="miter"
        ></line>{' '}
        <path
          data-color="color-2"
          data-stroke="none"
          fill="none"
          d="M4,19c0-1.657,1.343-3,3-3h2c1.657,0,3,1.343,3,3H4z"
          strokeLinejoin="miter"
          strokeLinecap="square"
        ></path>{' '}
        <circle
          data-color="color-2"
          data-stroke="none"
          fill="none"
          cx="8"
          cy="13"
          r="2"
          strokeLinejoin="miter"
          strokeLinecap="square"
        ></circle>
      </g>
    </svg>
  );
}
