import { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import 'react-phone-number-input/style.css';
// import { isValidPhoneNumber } from 'react-phone-number-input';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import I18n from '../i18n/I18n';
import { Box, FormControl, Button, Grid, RadioGroup, Radio, FormControlLabel, Typography } from '@material-ui/core';
import BulletPoints from '../Components/BulletPoints';
import StepPage from '../Components/StepPage';
import ProceedIcon from './ProceedIcon';
import { app } from '../constants/common';

function PaymentPage({ history, languageComponent }) {
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [paymentError, setPaymentError] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [customerData, setCustomerData] = useState(undefined);
  const [paymentAccounts, setPaymentAccounts] = useState([]);
  const [paymentAccount, setPaymentAccount] = useState();
  const [missingData, setMissingData] = useState(false);
  const data = JSON.parse(sessionStorage.getItem('customerSession'));

  useEffect(() => {
    if (!data?.accounts) {
      setErrorMessages(['payment.panel.errorMessageNoPhone']);
    }
  });

  useEffect(() => {
    const hasCustomerData = data?.customer;
    // Has to have at least one active payment method
    const hasPaymentAccounts = data?.accounts?.length > 0;
    // setErrorMessages(['payment.panel.errorMessageNoPhone']);

    if (!hasCustomerData || !hasPaymentAccounts) {
      setCustomerData(undefined);
      setMissingData(true);
      setErrorMessages(['payment.panel.errorMessageMissingData']);
    } else {
      let selectedPaymentAccount = { isChecked: true };
      if (data?.accounts?.length > 0) {
        selectedPaymentAccount = {...selectedPaymentAccount,...data.accounts.find(
          (account) => account.isDefault
        )};
      }
      const paymentAccountsWithChecks = data.accounts.map((item) => ({ ...item, isChecked: item.isDefault ? true: false }));
      setPaymentAccount(selectedPaymentAccount);
      setCustomerData(data.customer);
      setPaymentAccounts([...paymentAccountsWithChecks]);
      setMissingData(false);
      setErrorMessages([]);
    }
  }, [history]);

  const onProceed = () => {
    let errorMessages = [];

    if (!paymentAmount) {
      setPaymentError(true);
      errorMessages.push('payment.panel.errorMessagePaymentRequired');
    } else {
      setPaymentError(false);
    }

    if (!paymentAccount.accountId || paymentAccount.accountId.trim().length === 0) {
      errorMessages.push('payment.panel.errorMessagePhoneRequired');
    }
    // Validate with e.164 regexp here
    // if (paymentAccount.accountId && !isValidPhoneNumber(paymentAccount.accountId || '')) {
    //   errorMessages.push('payment.panel.errorMessagePhoneInvalid');
    // }

    if (errorMessages.length === 0) {
      setPaymentError(false);
      setErrorMessages([]);

      // const selectedPayMethod = paymentMethods.find(
      //   (method) => method.paymethodID === paymentMethodValue
      // );

      sessionStorage.setItem(
        'customerSession',
        JSON.stringify({
          ...JSON.parse(sessionStorage.getItem('customerSession')),
          amount: paymentAmount,
          phonenumber: paymentAccount.accountId,
          paymethodID: paymentAccount.paymethodID,
          payMethodLabel: paymentAccount.name,
        })
      );

      history.push('/payment-confirm');
    } else {
      setErrorMessages(errorMessages);
    }
  };

  const goBack = () => history.goBack();

  const bulletPoints = (
    <>
      <BulletPoints text={I18n.t('payment.stepBlock.firstBullet')} />
      <BulletPoints text={I18n.t('payment.stepBlock.secondBullet')} />
      <BulletPoints text={I18n.t('payment.stepBlock.thirdBullet')} />
    </>
  );

  const paperContent = (
    <>
      <Grid container alignItems="center" justify="flex-start">
        <Grid item xs={12}>
          <p style={{ color: '#4d4846' }}>
            {I18n.t('payment.panel.yourCustomerCode')}{' '}
            <b>{customerData && customerData.customerNumber}</b>
          </p>
        </Grid>
      </Grid>
      <Grid container alignItems="center" justify="flex-start">
        <Grid item xs={12}>
          <p style={{ color: '#4d4846' }}>
            {I18n.t('payment.panel.customerName')}{' '}
            <b>{customerData && customerData.customerName}</b>
          </p>
        </Grid>
      </Grid>
      {paymentAccounts &&
        <>
          <Grid container>
            <Grid item xs={12} md={8}>
              <FormControl
                variant="outlined"
                fullWidth
                style={{ marginTop: '15px', bottomMargin: '15px' }}
              >
                <CurrencyTextField
                  variant="outlined"
                  fullWidth
                  error={paymentError}
                  label={I18n.t('payment.panel.enterAmount')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  // The below props is buggy for copying/pasting negative numbers
                  // minimumValue="0"
                  value={paymentAmount}
                  onBlur={(event) => {
                    let paymentVal = event.target.value;
                    if (paymentVal) {
                      paymentVal = paymentVal.replace(/-/g, '');
                      paymentVal = parseFloat(paymentVal.replace(/\s/g, ''));
                    } else {
                      paymentVal = 0;
                    }

                    setPaymentAmount(paymentVal);
                  }}
                  textAlign={'left'}
                  digitGroupSeparator={' '}
                  currencySymbol="F CFA"
                  outputFormat="string"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={8}>
              <FormControl component="fieldset" style={{ marginTop: '20px' }}>
                <div style={{ marginBottom: '5px', color: '#4d4846' }}>
                  {I18n.t('payment.panel.selectPaymentMethod')}
                </div>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={8}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    style={{ color: '#4d4846'}}
                  >
                    {paymentAccounts?.map((account, idx) => <FormControlLabel key={idx} onClick={() => {
                      const tempPaymentAccounts = paymentAccounts;
                      const option = tempPaymentAccounts.find(item => item.accountId === account.accountId);
                      if(!option) return;
                      const index = tempPaymentAccounts.indexOf(option);
                      tempPaymentAccounts[index] = {
                        ...tempPaymentAccounts[index],
                        isChecked: !account.isChecked
                      };
                      const result = tempPaymentAccounts.map(item => {
                        if(item.accountId === account.accountId) {
                          setPaymentAccount({ ...item, isChecked: true });
                          return { ...item, isChecked: true };
                        }else{
                          return { ...item, isChecked: false };
                        }
                      });
                      setPaymentAccounts([...result]);
                    }} value={account.accountId} checked={account?.isChecked} control={<Radio style={{ color: '#4d4846' }} />} label={account.paymethodID + ' ' + '(' + account?.accountId + ')'} />)}
                  </RadioGroup>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
          {!missingData && (
            <Grid container justify="flex-start">
              <Grid item xs={12} md={8} style={{ flex: 'none', width: 'inherit' }}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  fullWidth
                  style={{
                    backgroundColor: 'rgba(232, 64, 55, 1)',
                    marginTop: '10px',
                    textTransform: 'none',
                  }}
                  onClick={() => {
                    onProceed();
                  }}
                >
                  <ProceedIcon />
                  {I18n.t('payment.panel.proceed')}
                </Button>
              </Grid>
            </Grid>
          )}
        </>}
      {errorMessages && errorMessages.length > 0 && (
        <>
          <Grid container>
            <Grid item xs={12} style={{ marginTop: '20px', color: '#e84037' }}>
              <b>{I18n.t('payment.panel.error')}</b>
              {errorMessages.map((errorMessage) => (
                <div key={errorMessage}>{I18n.t(errorMessage)}</div>
              ))}
            </Grid>
          </Grid>
          {!paymentAccounts && <Grid container justify="flex-start">
            <Grid item xs={12} md={8} style={{ flex: 'none', width: 'inherit' }}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                fullWidth
                style={{
                  backgroundColor: '#4d4846',
                  marginTop: '10px',
                  textTransform: 'none',
                }}
                onClick={goBack}
              >
                <Typography style={{ marginRight: 25 }}>&larr;</Typography>
                {I18n.t('payment.panel.previous')}
              </Button>
            </Grid>
          </Grid>}
        </>
      )}
    </>
  );

  return (
    <>
      <Helmet>
        <title>{app.appTitle} | {I18n.t('payment.tabTitle')} </title>
      </Helmet>
      <StepPage
        stepTitle={I18n.t('payment.stepBlock.title')}
        bulletPoints={bulletPoints}
        headline={I18n.t('payment.stepBlock.headline')}
        paperContent={paperContent}
        languageComponent={languageComponent}
      />
    </>
  );
}

export default withRouter(PaymentPage);
