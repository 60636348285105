import React from 'react';
import './SearchPage.css';

export default function SearchIcon() {
  return (
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      className="searchIcon"
      data-type="outline"
      data-name="search-3"
    >
      <g transform="translate(0, 0)" fill="none">
        <line
          data-cap="butt"
          fill="none"
          stroke="currentColor"
          vectorEffect="non-scaling-stroke"
          strokeMiterlimit="10"
          x1="18.071"
          y1="18.071"
          x2="23"
          y2="23"
          strokeLinejoin="miter"
          strokeLinecap="butt"
        ></line>{' '}
        <path
          data-cap="butt"
          data-color="color-2"
          fill="none"
          stroke="currentColor"
          vectorEffect="non-scaling-stroke"
          strokeMiterlimit="10"
          d="M11.992,10.737L14.2,12.4 c0.504,0.378,0.8,0.97,0.8,1.6v1H7v-1c0-0.63,0.296-1.222,0.8-1.6l2.208-1.663"
          strokeLinejoin="miter"
          strokeLinecap="butt"
        ></path>{' '}
        <path
          data-color="color-2"
          fill="none"
          stroke="currentColor"
          vectorEffect="non-scaling-stroke"
          strokeLinecap="square"
          strokeMiterlimit="10"
          d="M11,11 L11,11c-1.105,0-2-0.895-2-2V8c0-1.105,0.895-2,2-2h0c1.105,0,2,0.895,2,2v1C13,10.105,12.105,11,11,11z"
          strokeLinejoin="miter"
        ></path>{' '}
        <circle
          fill="none"
          stroke="currentColor"
          vectorEffect="non-scaling-stroke"
          strokeLinecap="square"
          strokeMiterlimit="10"
          cx="11"
          cy="11"
          r="10"
          strokeLinejoin="miter"
        ></circle>
      </g>
    </svg>
  );
}
