import React from 'react';
import './SearchPage.css';

export default function CustomerDepotIcon() {
  return (
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      className="labelIcon"
      data-type="outline"
      data-name="home-search"
    >
      <g transform="translate(0, 0)" fill="none">
        <polyline
          fill="none"
          stroke="currentColor"
          vectorEffect="non-scaling-stroke"
          strokeLinecap="square"
          strokeMiterlimit="10"
          points="20,10 11,2 2,10 2,23 13,23 "
          strokeLinejoin="miter"
        ></polyline>{' '}
        <line
          data-cap="butt"
          data-color="color-2"
          fill="none"
          stroke="currentColor"
          vectorEffect="non-scaling-stroke"
          strokeMiterlimit="10"
          x1="23"
          y1="23"
          x2="20.121"
          y2="20.121"
          strokeLinejoin="miter"
          strokeLinecap="butt"
        ></line>{' '}
        <circle
          data-color="color-2"
          fill="none"
          stroke="currentColor"
          vectorEffect="non-scaling-stroke"
          strokeLinecap="square"
          strokeMiterlimit="10"
          cx="18"
          cy="18"
          r="3"
          strokeLinejoin="miter"
        ></circle>
      </g>
    </svg>
  );
}
