import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ReCAPTCHA from 'reaptcha';
import publicIp from 'public-ip';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import './SearchPage.css';
import I18n from '../i18n/I18n';
import StepPage from '../Components/StepPage';
import BulletPoints from '../Components/BulletPoints';
import SearchIcon from './SearchIcon';
import CustomerIcon from './CustomerIcon';
import CustomerDepotIcon from './CustomerDepotIcon';
import RegNumberIcon from './RegNumberIcon';
import ArrowSVG from '../Components/ArrowSVG';
import { app } from '../constants/common';

function SearchPage({ history, languageComponent }) {
  const [customerCode, setCustomerCode] = useState('');
  const [responseObj, setResponseObj] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [newErrorMessage, setNewErrorMessage] = useState();

  // useEffect(() => {
  //   const data = JSON.parse(sessionStorage.getItem('customerSession'));

  //   const hasAcceptedTermsAndConditions =
  //     data && data.hasAcceptedTermsAndConditions;

  //   if (!hasAcceptedTermsAndConditions) {
  //     history.push('/accept-disclaimer');
  //   }
  // }, [history]);

  const callSearchAPI = async () => {
    setIsLoading(true);
    setErrorMessage("")
    if (!customerCode || customerCode.trim().length === 0) {
      setErrorMessage('search.panel.errorMessageNoValue');
      setResponseObj(undefined);
    } else if (!customerCode.match(app.customerCodeRegex)) {
      setErrorMessage('search.panel.errorMessageInvalidCustomerCode');
      setResponseObj(undefined);
    } else {
      let response = {};
      let body = {};
      try {
        response = await fetch(`/initiateForAccount?destination=${customerCode}`);
        body = await response.json();
      } catch (e) {
        // Generally should never come here...
        response = { status: 500 };
        body = { customErrorCode: 50000, originalErrorCode: 50000 };
      }

      if (response.status >= 400) {
        setErrorMessage({
          message: `serverErrorMessages.${body.customErrorCode}`,
          originalErrorCode: body.originalErrorCode,
        });
        setNewErrorMessage({
          respCode: body?.respCode,
          devMsg: body?.devMsg,
          customerMsg: body?.customerMsg?.find( el => el?.language === I18n.locale)
        });
        setResponseObj(undefined);
      } else {
        setErrorMessage('');
        setResponseObj(body);
      }
    }
    setIsLoading(false);
  };

  const bulletPoints = (
    <>
      <BulletPoints text={I18n.t('search.stepBlock.firstBullet')} />
      <BulletPoints text={I18n.t('search.stepBlock.secondBullet')} />
      <BulletPoints text={I18n.t('search.stepBlock.thirdBullet')} />
    </>
  );

  const labelStyle = {
    textAlign: 'start',
    lineHeight: '59px',
    backgroundColor: 'rgba(43, 48, 57, 0.71)',
    borderBottom: '1px solid grey',
    color: 'white',
    paddingLeft: '30px',
  };

  const recaptchaRef = React.createRef();

  const paperContent = (
    <>
      <Grid container>
        <Grid item xs={12}>
          <p style={{ color: '#4d4846' }}>{I18n.t('search.panel.enterYour')}</p>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="outlined-number"
            type="text"
            placeholder="***************"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => {
              setCustomerCode(event.target.value);
            }}
            value={customerCode}
            error={errorMessage ? true : false}
            style={{
              marginBottom: '20px',
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            fullWidth
            style={{
              backgroundColor: 'rgba(232, 64, 55, 1)',
              height: '57px',
            }}
            onClick={() => {
              callSearchAPI();
            }}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress color="inherit" />
            ) : (
              <>
                <SearchIcon />
                {I18n.t('search.panel.buttonSearch')}
              </>
            )}
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <p style={{ color: '#4d4846' }}>
            {I18n.t('search.panel.searchResultsBelow')}
          </p>
        </Grid>
      </Grid>
      {errorMessage && (
        <Grid container>
          <Grid item xs={12}>
            <p style={{ color: '#e84037' }}>
              <b>{I18n.t('search.panel.error')} </b>{' '}
              {errorMessage.originalErrorCode
                ? I18n.t(errorMessage.message, {
                  defaults: [{ scope: 'serverErrorMessages.50000' }],
                  originalErrorCode: errorMessage.originalErrorCode,
                })
                :newErrorMessage?.customerMsg ?
                  newErrorMessage?.customerMsg?.content :
                  I18n.t(errorMessage)
              }
            </p>
          </Grid>
        </Grid>
      )}

      {responseObj && responseObj.customer && (
        <>
          <Grid container>
            <Grid item xs={12} sm={6} style={labelStyle}>
              <CustomerIcon />
              {I18n.t('search.panel.customerName')}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              style={{
                textAlign: 'start',
                lineHeight: '59px',
                paddingLeft: '30px',
              }}
            >
              {responseObj.customer.customerName}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={6} style={labelStyle}>
              <CustomerDepotIcon />
              {I18n.t('search.panel.customerDepot')}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              style={{
                textAlign: 'start',
                lineHeight: '59px',
                backgroundColor: 'rgba(249, 249, 249, 1)',
                paddingLeft: '30px',
              }}
            >
              {responseObj.customer.customerAddress}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={6} style={labelStyle}>
              <RegNumberIcon />
              {I18n.t('search.panel.registrationNumber')}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              style={{
                textAlign: 'start',
                lineHeight: '59px',
                paddingLeft: '30px',
              }}
            >
              <b>{responseObj.customer.ref2}</b>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={6} style={labelStyle}>
              <CustomerDepotIcon />
              {I18n.t('search.panel.company')}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              style={{
                textAlign: 'start',
                lineHeight: '59px',
                backgroundColor: 'rgba(249, 249, 249, 1)',
                paddingLeft: '30px',
              }}
            >
              {responseObj.customer.ref1}
            </Grid>
          </Grid>
        </>
      )}
      {responseObj && responseObj.customer && (
        <Grid container justify="center">
          <Grid item xs={12} sm={8}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              fullWidth
              style={{
                backgroundColor: 'rgba(232, 64, 55, 1)',
                marginTop: '60px',
              }}
              onClick={() => {
                if (window.CLIENT_ENV?.enableRecaptcha) {
                  recaptchaRef.current.reset();
                  recaptchaRef.current.execute();
                } else {
                  sessionStorage.setItem(
                    'customerSession',
                    JSON.stringify(responseObj)
                  );
                  history.push('/payment-page');
                }
              }}
            >
              {I18n.t('search.panel.continueToPayment')}
              <ArrowSVG disabled={false} />
            </Button>
            {window.CLIENT_ENV?.enableRecaptcha && (
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={window.CLIENT_ENV?.recaptchaSitekey}
                badge="bottomright"
                onVerify={async (token) => {
                  if (token) {
                    let response = {};
                    const clientIp = await publicIp.v4();
                    try {
                      response = await fetch('/recaptcha', {
                        method: 'POST',
                        headers: {
                          Accept: 'application/json',
                          'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                          token,
                          clientIp,
                        }),
                      });
                    } catch (e) {
                      response = { status: 500 };
                    }

                    if (response.status >= 400) {
                      setErrorMessage('search.panel.errorRecaptcha');
                    } else {
                      sessionStorage.setItem(
                        'customerSession',
                        JSON.stringify(responseObj)
                      );
                      history.push('/payment-page');
                    }
                  }
                }}
              />
            )}
          </Grid>
        </Grid>
      )}
    </>
  );

  return (
    <>
      <Helmet>
        <title>{app.groupName.fr} | {I18n.t('search.tabTitle')} </title>
      </Helmet>
      <StepPage
        stepTitle={I18n.t('search.stepBlock.title')}
        bulletPoints={bulletPoints}
        headline={I18n.t('search.stepBlock.headline')}
        paperContent={paperContent}
        languageComponent={languageComponent}
      />
    </>
  );
}

export default withRouter(SearchPage);
