import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { v4 as uuidv4 } from 'uuid';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';

import I18n from '../i18n/I18n';
import BulletPoints from '../Components/BulletPoints';
import ArrowSVG from '../Components/ArrowSVG';
import StepPage from '../Components/StepPage';
import { manageError } from '../utils/errorChecker';
import { app } from '../constants/common';

function PaymentPage({ history, languageComponent }) {
  const [errorMessage, setErrorMessage] = useState('');
  // const [newErrorMessage, setNewErrorMessage] = useState();
  const [paymentData, setPaymentData] = useState(undefined);
  // const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] =
  //   useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem('customerSession'));
    const hasCustomerData = data?.customer;
    const hasAmount = data?.amount;
    const hasPhoneNumber = data?.phonenumber;
    const hasPaymethodID = data?.paymethodID;
    const hasPaymentAccounts = data?.accounts?.length > 0;

    if (
      !hasCustomerData ||
      !hasAmount ||
      !hasPhoneNumber ||
      !hasPaymethodID ||
      !hasPaymentAccounts
    ) {
      setErrorMessage('paymentConfirm.panel.errorMessageMissingData');
      setPaymentData(undefined);
    } else {
      setErrorMessage('');
      setPaymentData(data);
      // setTermsAndConditionsAccepted(data.hasAcceptedTermsAndConditions);
    }
  }, [history]);

  const callPayApi = async () => {
    setIsLoading(true);
    let response = {};
    let body = {};
    let txId = uuidv4();
    // If the txId already exists in  the session then use it
    if (paymentData && paymentData.txId) {
      txId = paymentData.txId;
    }
    // Add the txId to sessionStorage before calling in case the API call fails
    sessionStorage.setItem(
      'customerSession',
      JSON.stringify({
        ...JSON.parse(sessionStorage.getItem('customerSession')),
        txId,
      })
    );
    
    try {
      response = await fetch('/pay', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          payItemID: paymentData.payItemID,
          paymethodID: paymentData.paymethodID,
          payerID: paymentData.phonenumber,
          amount: paymentData.amount,
          txId,
        }),
      });
      body = await response.json();
      // setErrorMessage({
      //   message: `serverErrorMessages.${[String(body?.customErrorCode)]}`,
      //   originalErrorCode: body?.originalErrorCode,
      //   error: body?.error
      // });

    } catch (e) {
      // Generally should never come here...
      response = { status: 500 };
      body = { customErrorCode: '50000', originalErrorCode: '50000' };
    }

    setIsLoading(false);

    if (response.status >= 400) {
      setErrorMessage({
        message: `serverErrorMessages.${[String(body?.customErrorCode)]}`,
        originalErrorCode: body?.originalErrorCode || body?.error?.respCode,
        error: body?.error
      });
    } else {
      sessionStorage.setItem(
        'customerSession',
        JSON.stringify({
          ...JSON.parse(sessionStorage.getItem('customerSession')),
          paymentStatus: body.status,
          paidAt: body.paidAt,
          ptn: body.ptn,
          txId: body.txId,
        })
      );

      history.push('/payment-confirm-success');
    }
  };

  const labelStyle = {
    textAlign: 'start',
    lineHeight: '59px',
    backgroundColor: 'rgba(43, 48, 57, 0.71)',
    borderBottom: '1px solid grey',
    color: 'white',
    paddingLeft: '30px',
  };

  const getPaymentInstruction = (paymentMethodID) => {
    switch (paymentMethodID) {
    case 'MTN':
      return I18n.t('paymentConfirm.instruction.mtn');
    case 'ORANGE':
      return I18n.t('paymentConfirm.instruction.orange');
    case 'YUP':
      return I18n.t('paymentConfirm.instruction.yup');
    default:
      return '';
    }
  };

  const bulletPoints = (
    <>
      <BulletPoints text={I18n.t('paymentConfirm.stepBlock.firstBullet')} />
      <BulletPoints text={I18n.t('paymentConfirm.stepBlock.secondBullet')} />
      <BulletPoints text={I18n.t('paymentConfirm.stepBlock.thirdBullet')} />
    </>
  );

  const ColorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText('rgba(2, 166, 0, 1)'),
      backgroundColor: 'rgba(2, 166, 0, 1)',
      '&:hover': {
        backgroundColor: 'rgba(2, 166, 0, 1)',
      },
    },
  }))(Button);

  const paperContent = (
    <>
      <Grid container style={{ textAlign: 'center' }}>
        <Grid item xs={12}>
          <ReportProblemOutlinedIcon
            style={{
              color: 'rgb(245,166,35)',
              fontSize: 80,
              marginTop: '-20px',
              paddingTop: 0,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <h3 style={{ color: '#4d4846' }}>
            {I18n.t('paymentConfirm.panel.title')}
          </h3>
        </Grid>
      </Grid>
      <Grid container alignItems="center" justify="flex-start">
        <Grid item xs={12} sm={6} style={labelStyle}>
          {I18n.t('paymentConfirm.panel.yourCustomerCode')}{' '}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            textAlign: 'start',
            lineHeight: '59px',
            paddingLeft: '30px',
          }}
        >
          {paymentData &&
            paymentData.customer &&
            paymentData.customer.customerNumber}
        </Grid>
      </Grid>
      <Grid container alignItems="center" justify="flex-start">
        <Grid item xs={12} sm={6} style={labelStyle}>
          {I18n.t('paymentConfirm.panel.customerName')}{' '}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            textAlign: 'start',
            lineHeight: '59px',
            backgroundColor: 'rgba(249, 249, 249, 1)',
            paddingLeft: '30px',
          }}
        >
          {paymentData &&
            paymentData.customer &&
            paymentData.customer.customerName}
        </Grid>
      </Grid>
      <Grid container alignItems="center" justify="flex-start">
        <Grid item xs={12} sm={6} style={labelStyle}>
          {I18n.t('paymentConfirm.panel.paymentAmount')}{' '}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            textAlign: 'start',
            lineHeight: '59px',
            paddingLeft: '30px',
          }}
        >
          F CFA {paymentData && paymentData.amount.toFixed(2)}
        </Grid>
      </Grid>
      <Grid container alignItems="center" justify="flex-start">
        <Grid item xs={12} sm={6} style={labelStyle}>
          {I18n.t('paymentConfirm.panel.customerDepot')}{' '}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            textAlign: 'start',
            lineHeight: '59px',
            backgroundColor: 'rgba(249, 249, 249, 1)',
            paddingLeft: '30px',
          }}
        >
          {paymentData &&
            paymentData.customer &&
            paymentData.customer.customerAddress}
        </Grid>
      </Grid>
      <Grid container alignItems="center" justify="flex-start">
        <Grid item xs={12} sm={6} style={labelStyle}>
          {I18n.t('paymentConfirm.panel.paymentMethod')}{' '}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            textAlign: 'start',
            lineHeight: '59px',
            paddingLeft: '30px',
          }}
        >
          {paymentData && paymentData.payMethodLabel}
        </Grid>
      </Grid>
      <Grid container alignItems="center" justify="flex-start">
        <Grid item xs={12} sm={6} style={labelStyle}>
          {I18n.t('paymentConfirm.panel.phoneNumber')}{' '}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            textAlign: 'start',
            lineHeight: '59px',
            backgroundColor: 'rgba(249, 249, 249, 1)',
            paddingLeft: '30px',
          }}
        >
          {paymentData && paymentData.phonenumber}
        </Grid>
      </Grid>
      <Grid container  justify="flex-start">
        <Grid item xs={12} sm={6} style={labelStyle}>
          {I18n.t('paymentConfirm.panel.instruction')}{' '}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            textAlign: 'start',
            lineHeight: '25px',
            paddingLeft: '30px',
            fontWeight: 'bold',
            color: '#e84037',
          }}
        >
          {paymentData && getPaymentInstruction(paymentData.paymethodID)}
        </Grid>
      </Grid>
      {/* <Grid container alignItems="center" justify="center">
        <Grid item xs={12} style={{ marginTop: '20px' }}>
          <small style={{ color: '#4d4846' }}>
            {I18n.t('paymentConfirm.panel.termsAndConditions1')}
            <b>
              {termsAndConditionsAccepted
                ? I18n.t('paymentConfirm.panel.termsAndConditionsAccepted')
                : I18n.t('paymentConfirm.panel.termsAndConditionsRefused')}{' '}
            </b>
          </small>
        </Grid>
      </Grid> */}
      {!errorMessage ? (
        <Grid container alignItems="center" justify="center">
          <Grid item>
            {isLoading ? (
              <CircularProgress
                style={{
                  marginTop: '40px',
                  color: 'rgb(245,166,35)',
                }}
                size="30px"
              />
            ) : (
              <ColorButton
                variant="contained"
                color="primary"
                style={{
                  marginTop: '40px',
                  textTransform: 'none',
                }}
                fullWidth
                onClick={() => callPayApi()}
                disabled={isLoading}
              >
                {I18n.t('paymentConfirm.panel.buttonConfirm')}
                <ArrowSVG />
              </ColorButton>
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          <Grid item xs={12} style={{ marginTop: '20px', color: '#e84037' }}>
            <b>{I18n.t('paymentConfirm.panel.error')}</b>
            {
              errorMessage?.error ?
                manageError(errorMessage?.error?.respCode)
                : errorMessage?.originalErrorCode ? 
                  I18n.t(errorMessage?.message, {
                    defaults: [{ scope: 'serverErrorMessages.50000' }],
                    originalErrorCode: errorMessage?.originalErrorCode,
                  })
                  : I18n.t(errorMessage) 
            }
          </Grid>
        </Grid>
      )}
    </>
  );

  return (
    <>
      <Helmet>
        <title>{app.appTitle} | {I18n.t('paymentConfirm.tabTitle')} </title>
      </Helmet>
      <StepPage
        stepTitle={I18n.t('paymentConfirm.stepBlock.title')}
        bulletPoints={bulletPoints}
        headline={I18n.t('paymentConfirm.stepBlock.headline')}
        paperContent={paperContent}
        languageComponent={languageComponent}
      />
    </>
  );
}

export default withRouter(PaymentPage);
