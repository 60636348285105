import React from 'react';
import Grid from '@material-ui/core/Grid';

import './StepBlock.css';

export default function StepBlock({ title, headline, content }) {
  return (
    <Grid container justify="space-between">
      <Grid item xs={10}>
        <p>{title}</p>
      </Grid>
      <Grid item xs={2}>
        <span className="bigArrow">
          <svg
            id="nc_icon"
            x="0px"
            y="0px"
            viewBox="0 0 24 24"
            className="bigArrowSVG"
            data-type="glyph"
            data-name="round-right"
          >
            <g fill="currentColor">
              <path
                fill="currentColor"
                d="M5,16h6v6.1L23.6,12L11,1.9V8H5c-2.2,0-4,1.8-4,4S2.8,16,5,16z"
              ></path>
            </g>
          </svg>
        </span>
      </Grid>
      <Grid item xs={12}>
        <b>{headline}</b>
      </Grid>
      <Grid item xs={12}>
        <p>{content}</p>
      </Grid>
    </Grid>
  );
}
