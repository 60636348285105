import React from 'react';
import './SearchPage.css';

export default function RegNumberIcon() {
  return (
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      className="labelIcon"
      data-type="outline"
      data-name="focus"
    >
      <g transform="translate(0, 0)" fill="none">
        <path
          fill="none"
          stroke="currentColor"
          vectorEffect="non-scaling-stroke"
          strokeLinecap="square"
          strokeMiterlimit="10"
          d="M19,19H5v-1.313 c0-1.213,0.725-2.307,1.846-2.772C7.981,14.445,9.67,14,12,14s4.019,0.445,5.154,0.916C18.275,15.38,19,16.474,19,17.687V19z"
          strokeLinejoin="miter"
        ></path>{' '}
        <circle
          fill="none"
          stroke="currentColor"
          vectorEffect="non-scaling-stroke"
          strokeLinecap="square"
          strokeMiterlimit="10"
          cx="12"
          cy="7"
          r="4"
          strokeLinejoin="miter"
        ></circle>{' '}
        <polyline
          data-color="color-2"
          fill="none"
          stroke="currentColor"
          vectorEffect="non-scaling-stroke"
          strokeLinecap="square"
          strokeMiterlimit="10"
          points=" 1,5 1,1 5,1 "
          strokeLinejoin="miter"
        ></polyline>{' '}
        <polyline
          data-color="color-2"
          fill="none"
          stroke="currentColor"
          vectorEffect="non-scaling-stroke"
          strokeLinecap="square"
          strokeMiterlimit="10"
          points=" 23,5 23,1 19,1 "
          strokeLinejoin="miter"
        ></polyline>{' '}
        <polyline
          data-color="color-2"
          fill="none"
          stroke="currentColor"
          vectorEffect="non-scaling-stroke"
          strokeLinecap="square"
          strokeMiterlimit="10"
          points=" 1,19 1,23 5,23 "
          strokeLinejoin="miter"
        ></polyline>{' '}
        <polyline
          data-color="color-2"
          fill="none"
          stroke="currentColor"
          vectorEffect="non-scaling-stroke"
          strokeLinecap="square"
          strokeMiterlimit="10"
          points=" 23,19 23,23 19,23 "
          strokeLinejoin="miter"
        ></polyline>
      </g>
    </svg>
  );
}
