import { Box } from "@material-ui/core";
import React from "react";
import { app } from "../constants/common";

const LogoGroup = () => {
  const { logo, name } = app;

  return (
    <div style={{ textAlign: "center" }}>
      <Box
        component="img"
        sx={{
          height: "150px",
          marginTop: { xs: "40px", md: "60px" },
          marginBottom: { xs: "20px", md: "40px" },
        }}
        alt={`logo ${name}`}
        src={logo}
      />
    </div>
  );
};

export default LogoGroup;
