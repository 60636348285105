import React from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LogoGroup from '../shared/logoGroup';
import './StepPage.css';

export default function StepPage({
  showSteps = true,
  languageComponent,
  stepTitle,
  headline,
  bulletPoints,
  paperContent,
}) {
  const isLarge = useMediaQuery('(min-width:600px)');


  return (
    <div
      style={{
        margin: 'auto',
        width: isLarge ? '80%' : '95%',
        paddingBottom: '40px',
      }}
    >
      <Hidden xsDown>
        <div
          className={'bg2'}
          style={{
            backgroundImage: 'url(./images/background2.jpg)',
          }}
        />
      </Hidden>
      <Hidden smUp>
        <div
          className={'bg3'}
          style={{
            backgroundImage: 'url(./images/background3.jpg)',
          }}
        />
      </Hidden>
      <Grid container alignItems="center" justify="center">
        <Grid item xs={9} sm={4}>
          <LogoGroup />
        </Grid>
        {languageComponent}
        <Grid container alignItems="center" justify="center">
          {showSteps && (
            <Grid item xs={12} md={4} lg={6}>
              <Hidden mdUp>
                <Grid container>
                  <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <h1>{stepTitle}</h1>
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <p>{headline}</p>
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <Grid
                      container
                      alignItems="center"
                      justify="center"
                      style={{ marginBottom: '20px' }}
                    >
                      {bulletPoints}
                    </Grid>
                  </Grid>
                </Grid>
              </Hidden>
              <Hidden smDown>
                <Grid container alignItems="flex-end" justify="flex-end">
                  <Grid item xs={12} lg={8}>
                    <h1>{stepTitle}</h1>
                  </Grid>
                  <Grid item xs={12} lg={8}>
                    <p>{headline}</p>
                  </Grid>
                  <Grid item xs={12} lg={8}>
                    <Grid
                      container
                      alignItems="center"
                      justify="center"
                      style={{ marginBottom: '20px' }}
                    >
                      {bulletPoints}
                    </Grid>
                  </Grid>
                </Grid>
              </Hidden>
            </Grid>
          )}
          <Grid item xs={12} md={8} lg={6}>
            <Hidden smUp>
              <Paper
                style={{ padding: isLarge ? '50px' : '35px' }}
                elevation={15}
              >
                {paperContent}
              </Paper>
            </Hidden>
            <Hidden xsDown>
              <Paper
                style={{ padding: '50px', marginLeft: '20px' }}
                elevation={15}
              >
                {paperContent}
              </Paper>
            </Hidden>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
