import React, { useState, useCallback } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import LandingPage from './LandingPage/LandingPage';
import SearchPage from './SearchPage/SearchPage';
import PaymentPage from './PaymentPage/PaymentPage';
import PaymentConfirmPage from './PaymentConfirmPage/PaymentConfirmPage';
// import TermsAndConditions from './TermsAndConditions/TermsAndConditions';
import PaymentConfirmSuccessPage from './PaymentConfirmSuccessPage/PaymentConfirmSuccessPage';
import LanguageButtons from './Components/LanguageButtons';

export default function App() {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const languageComponent = (
    <LanguageButtons
      updateLanguage={() => {
        forceUpdate();
      }}
    />
  );
  return (
    <Router>
      <Switch>
        <Route path="/payment-confirm-success">
          <PaymentConfirmSuccessPage languageComponent={languageComponent} />
        </Route>
        <Route path="/payment-confirm">
          <PaymentConfirmPage languageComponent={languageComponent} />
        </Route>
        <Route path="/payment-page">
          <PaymentPage languageComponent={languageComponent} />
        </Route>
        <Route path="/search-page">
          <SearchPage languageComponent={languageComponent} />
        </Route>
        {/* <Route path="/accept-disclaimer">
          <TermsAndConditions languageComponent={languageComponent} />
        </Route> */}
        <Route path="/">
          <LandingPage languageComponent={languageComponent} />
        </Route>
      </Switch>
    </Router>
  );
}
