import React from 'react';
import './ProceedIcon.css';

export default function ProceedIcon() {
  return (
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      className="proceedIcon"
      data-type="outline"
      data-name="coins"
    >
      <g transform="translate(0, 0)" className="proceedIcon" fill="none">
        <path
          data-cap="butt"
          data-color="color-2"
          fill="none"
          stroke="currentColor"
          vectorEffect="non-scaling-stroke"
          strokeMiterlimit="10"
          d="M1,4v4c0,1.657,2.686,3,6,3 s6-1.343,6-3V4"
          strokeLinejoin="miter"
          strokeLinecap="butt"
        ></path>{' '}
        <path
          data-cap="butt"
          data-color="color-2"
          fill="none"
          stroke="currentColor"
          vectorEffect="non-scaling-stroke"
          strokeMiterlimit="10"
          d="M1,8v4c0,1.657,2.686,3,6,3 c1.537,0,2.938-0.29,4-0.765"
          strokeLinejoin="miter"
          strokeLinecap="butt"
        ></path>{' '}
        <path
          data-cap="butt"
          data-color="color-2"
          fill="none"
          stroke="currentColor"
          vectorEffect="non-scaling-stroke"
          strokeMiterlimit="10"
          d="M1,12v4c0,1.657,2.686,3,6,3 c1.537,0,2.939-0.289,4-0.764"
          strokeLinejoin="miter"
          strokeLinecap="butt"
        ></path>{' '}
        <ellipse
          data-color="color-2"
          fill="none"
          stroke="currentColor"
          vectorEffect="non-scaling-stroke"
          strokeLinecap="square"
          strokeMiterlimit="10"
          cx="7"
          cy="4"
          rx="6"
          ry="3"
          strokeLinejoin="miter"
        ></ellipse>{' '}
        <path
          data-cap="butt"
          fill="none"
          stroke="currentColor"
          vectorEffect="non-scaling-stroke"
          strokeMiterlimit="10"
          d="M11,12v4c0,1.657,2.686,3,6,3s6-1.343,6-3 v-4"
          strokeLinejoin="miter"
          strokeLinecap="butt"
        ></path>{' '}
        <path
          data-cap="butt"
          fill="none"
          stroke="currentColor"
          vectorEffect="non-scaling-stroke"
          strokeMiterlimit="10"
          d="M11,16v4c0,1.657,2.686,3,6,3 s6-1.343,6-3v-4"
          strokeLinejoin="miter"
          strokeLinecap="butt"
        ></path>{' '}
        <ellipse
          fill="none"
          stroke="currentColor"
          vectorEffect="non-scaling-stroke"
          strokeLinecap="square"
          strokeMiterlimit="10"
          cx="17"
          cy="12"
          rx="6"
          ry="3"
          strokeLinejoin="miter"
        ></ellipse>
      </g>
    </svg>
  );
}
