import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import I18n from '../i18n/I18n';
import ArrowSVG from '../Components/ArrowSVG';
import StepPage from '../Components/StepPage';
import { manageError } from '../utils/errorChecker';
import { app } from '../constants/common';

const STATUS_ENUM = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  ERRORED: 'ERRORED',
};

function PaymentPage({ history, languageComponent }) {
  const [errorMessage, setErrorMessage] = useState('');
  const [paymentData, setPaymentData] = useState(undefined);
  const [currentStatus, setCurrentStatus] = useState(STATUS_ENUM.PENDING);
  const [paidAt, setPaidAt] = useState('');
  const [stopInterval, setStopInterval] = useState(false);
  // const [newErrorMessage, setNewErrorMessage] = useState();

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem('customerSession'));
    const hasCustomerData = data?.customer;
    const hasAmount = data?.amount;
    const hasPhoneNumber = data?.phonenumber;
    const hasPaymethodID = data?.paymethodID;
    const hasPaymentAccounts = data?.accounts?.length > 0;
    const hasPaidAt = data?.paidAt;
    const hasPtn = data?.ptn;
    const hasTxId = data?.txId;

    if (
      !hasCustomerData ||
      !hasAmount ||
      !hasPhoneNumber ||
      !hasPaymethodID ||
      !hasPaymentAccounts ||
      !hasPaidAt ||
      (!hasPtn && !hasTxId)
    ) {
      setErrorMessage('paymentSuccess.panel.errorMessageMissingData');
      setPaymentData(undefined);
      setCurrentStatus(STATUS_ENUM.ERRORED);
    } else {
      setErrorMessage('');
      setPaymentData(data);
      setCurrentStatus(data.paymentStatus);
      setPaidAt(data.paidAt);
    }
  }, [history]);

  useInterval(
    async () => {
      // If the ptn AND txId value is missing, cannot query for status
      if (!paymentData || (!paymentData.ptn && !paymentData.txId)) {
        setErrorMessage('paymentSuccess.panel.errorMessageMissingData');
        setPaymentData(undefined);
        setCurrentStatus(STATUS_ENUM.ERRORED);
        setStopInterval(true);
      } else if (
        // If there is a ptn or txId value and current status is PENDING (not success and not error)
        currentStatus !== STATUS_ENUM.SUCCESS &&
        currentStatus !== STATUS_ENUM.ERRORED
      ) {
        let response = {};
        let body = {};
        try {
          response = await fetch(
            `/verify?ptn=${paymentData.ptn}&txId=${paymentData.txId}`
          );
          body = await response.json();
        } catch (e) {
          // Generally should never come here...
          response = { status: 500 };
          body = { customErrorCode: '50000', originalErrorCode: '50000' };
        }

        // This scenario should be rare, this is when PROXY API has issues
        // trying to connect to remote API
        if (response.status >= 400) {
          setErrorMessage({
            message: `serverErrorMessages.${[String(body.customErrorCode)]}`,
            originalErrorCode: body.originalErrorCode,
          });
          // setNewErrorMessage({
          //   respCode: body?.respCode,
          //   devMsg: body?.devMsg,
          //   customerMsg: body?.customerMsg?.find( el => el?.language === I18n.locale)
          // });
          setCurrentStatus(STATUS_ENUM.ERRORED);
          setPaidAt('');
          setStopInterval(true);
        } else {
          if (body.status === STATUS_ENUM.ERRORED) {
            // setErrorMessage('paymentSuccess.panel.errorPaymentFailed');
            setErrorMessage({
              message: `serverErrorMessages.${[String(body?.customErrorCode)]}`,
              originalErrorCode: body?.originalErrorCode || body?.error?.respCode,
              error: body?.error
            });
            setCurrentStatus(body.status);
            setPaidAt('');
            setStopInterval(true);
          } else if (body.status === STATUS_ENUM.SUCCESS) {
            setCurrentStatus(body.status);
            setPaidAt(body.paidAt);
            setStopInterval(true);
          }
        }
      } else {
        // Otherwise there is a PTN and the status is either SUCCESS OR ERRORED
        // Then just stop the interval
        setStopInterval(true);
      }
    },
    !stopInterval ? window.CLIENT_ENV?.verifyIntervalDelay || 10000 : null
  );

  const ColorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText('rgba(2, 166, 0, 1)'),
      backgroundColor: 'rgba(2, 166, 0, 1)',
      '&:hover': {
        backgroundColor: 'rgba(2, 166, 0, 1)',
      },
    },
  }))(Button);

  let panelTitle = (
    <Grid item xs={12}>
      <Loader type="Grid" color="rgb(245,166,35)" height={150} width={150} />
      <p>{I18n.t('paymentSuccess.panel.processingPayment')}</p>
    </Grid>
  );

  switch (currentStatus) {
  case STATUS_ENUM.ERRORED:
    panelTitle = (
      <>
        <Grid item xs={12}>
          <ErrorOutlineIcon
            style={{
              color: 'rgba(232, 64, 55, 1)',
              fontSize: 160,
              marginTop: '-20px',
              paddingTop: 0,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <h3 style={{ color: 'rgba(232, 64, 55, 1)' }}>
            <b>{I18n.t('paymentSuccess.panel.error')}</b>
            {
              errorMessage?.error ?
                manageError(errorMessage?.error?.respCode)
                : errorMessage?.originalErrorCode ? 
                  // manageError(errorMessage?.originalErrorCode)
                  I18n.t(errorMessage?.message, {
                    defaults: [{ scope: 'serverErrorMessages.50000' }],
                    originalErrorCode: errorMessage?.originalErrorCode,
                  })
                  : I18n.t(errorMessage)
            }
          </h3>
        </Grid>
        <Grid container alignItems="center" justify="center">
          <Grid item>
            <ColorButton
              variant="contained"
              color="primary"
              style={{
                marginTop: '40px',
                textTransform: 'none',
              }}
              onClick={() => {
                sessionStorage.clear();
                history.push('/search-page');
              }}
            >
              {I18n.t('paymentSuccess.panel.buttonMakeAnotherPayment')}
              <ArrowSVG />
            </ColorButton>
          </Grid>
        </Grid>
      </>
    );
    break;
  case STATUS_ENUM.SUCCESS:
    panelTitle = (
      <>
        <Grid item xs={12}>
          <CheckCircleOutlineIcon
            style={{
              color: 'rgb(13,177,0)',
              fontSize: 80,
              marginTop: '-20px',
              paddingTop: 0,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <h3 style={{ color: 'rgb(13,177,0)' }}>
            {I18n.t('paymentSuccess.panel.title')}
          </h3>
        </Grid>
      </>
    );
    break;
  default:
  }

  const labelStyle = {
    textAlign: 'start',
    lineHeight: '59px',
    backgroundColor: 'rgba(43, 48, 57, 0.71)',
    borderBottom: '1px solid grey',
    color: 'white',
    paddingLeft: '30px',
  };

  const customerDetails = (
    <>
      <Grid container alignItems="center" justify="flex-start">
        <Grid item xs={12} sm={6} style={labelStyle}>
          {I18n.t('paymentSuccess.panel.yourCustomerCode')}{' '}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            textAlign: 'start',
            lineHeight: '59px',
            paddingLeft: '30px',
          }}
        >
          {paymentData &&
            paymentData.customer &&
            paymentData.customer.customerNumber}
        </Grid>
      </Grid>
      <Grid container alignItems="center" justify="flex-start">
        <Grid item xs={12} sm={6} style={labelStyle}>
          {I18n.t('paymentSuccess.panel.customerName')}{' '}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            textAlign: 'start',
            lineHeight: '59px',
            backgroundColor: 'rgba(249, 249, 249, 1)',
            paddingLeft: '30px',
          }}
        >
          {paymentData &&
            paymentData.customer &&
            paymentData.customer.customerName}
        </Grid>
      </Grid>
      <Grid container alignItems="center" justify="flex-start">
        <Grid item xs={12} sm={6} style={labelStyle}>
          {I18n.t('paymentSuccess.panel.paymentAmount')}{' '}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            textAlign: 'start',
            lineHeight: '59px',
            paddingLeft: '30px',
          }}
        >
          F CFA {paymentData && paymentData.amount.toFixed(2)}
        </Grid>
      </Grid>
      <Grid container alignItems="center" justify="flex-start">
        <Grid item xs={12} sm={6} style={labelStyle}>
          {I18n.t('paymentSuccess.panel.customerDepot')}{' '}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            textAlign: 'start',
            lineHeight: '59px',
            backgroundColor: 'rgba(249, 249, 249, 1)',
            paddingLeft: '30px',
          }}
        >
          {paymentData &&
            paymentData.customer &&
            paymentData.customer.customerAddress}
        </Grid>
      </Grid>
      <Grid container alignItems="center" justify="flex-start">
        <Grid item xs={12} sm={6} style={labelStyle}>
          {I18n.t('paymentSuccess.panel.paymentMethod')}{' '}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            textAlign: 'start',
            lineHeight: '59px',
            paddingLeft: '30px',
          }}
        >
          {paymentData && paymentData.payMethodLabel}
        </Grid>
      </Grid>
      <Grid container alignItems="center" justify="flex-start">
        <Grid item xs={12} sm={6} style={labelStyle}>
          {I18n.t('paymentSuccess.panel.phoneNumber')}{' '}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            textAlign: 'start',
            lineHeight: '59px',
            backgroundColor: 'rgba(249, 249, 249, 1)',
            paddingLeft: '30px',
          }}
        >
          {paymentData && paymentData.phonenumber}
        </Grid>
      </Grid>
      <Grid container alignItems="center" justify="flex-start">
        <Grid item xs={12} sm={6} style={labelStyle}>
          {I18n.t('paymentSuccess.panel.paymentsConfirmed')}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            textAlign: 'start',
            lineHeight: '59px',
            paddingLeft: '30px',
          }}
        >
          {moment(paidAt).format('l LTS')}
        </Grid>
      </Grid>
      <Grid container alignItems="center" justify="center">
        <Grid item>
          <ColorButton
            variant="contained"
            color="primary"
            style={{
              marginTop: '40px',
              textTransform: 'none',
            }}
            onClick={() => {
              sessionStorage.clear();
              history.push('/search-page');
            }}
          >
            {I18n.t('paymentSuccess.panel.buttonMakeAnotherPayment')}
            <ArrowSVG />
          </ColorButton>
        </Grid>
      </Grid>
    </>
  );

  const paperContent = (
    <>
      <Grid container style={{ textAlign: 'center' }}>
        {panelTitle}
      </Grid>
      {currentStatus === STATUS_ENUM.SUCCESS && customerDetails}
    </>
  );

  return (
    <>
      <Helmet>
        <title>{app.appTitle} | {I18n.t('paymentSuccess.tabTitle')} </title>
      </Helmet>
      <StepPage
        paperContent={paperContent}
        showSteps={false}
        languageComponent={languageComponent}
      />
    </>
  );
}

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default withRouter(PaymentPage);
