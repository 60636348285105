import React from 'react';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ArrowSVG from '../Components/ArrowSVG';
import LogoGroup from '../shared/logoGroup';
import { app } from '../constants/common';
import I18n from '../i18n/I18n';
import './LandingPage.css';
import StepBlock from './StepBlock';

function LandingPage({ history, languageComponent }) {
  const {backgroundImage} = app
  
  return (
    <div
      style={{
        margin: 'auto',
        width: '70%',
      }}
    >
      <div
        className={'bg1'}
        style={{
          backgroundImage,
        }}
      />
      <Grid container alignItems="center" justify="center">
        <Grid item xs={9} sm={4}>
          <LogoGroup/>
        </Grid>
        {languageComponent}
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <h1>{I18n.t('landing.welcome')}</h1>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <p>{I18n.t('landing.readInfo')}</p>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <p>{I18n.t('landing.howItWorks')}</p>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4}>
            <StepBlock
              title={I18n.t('landing.step01.title')}
              headline={I18n.t('landing.step01.headline')}
              content={I18n.t('landing.step01.content')}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <StepBlock
              title={I18n.t('landing.step02.title')}
              headline={I18n.t('landing.step02.headline')}
              content={I18n.t('landing.step02.content')}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <StepBlock
              title={I18n.t('landing.step03.title')}
              headline={I18n.t('landing.step03.headline')}
              content={I18n.t('landing.step03.content')}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" justify="center">
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              style={{
                backgroundColor: 'rgba(232, 64, 55, 1)',
                width: '100%',
                marginTop: '50px',
              }}
              onClick={async () => {
                history.push('/search-page');
              }}
            >
              {I18n.t('landing.skip')}
              <ArrowSVG
                disabled={false}
                color="white"
                disabledColor="rgba(0, 0, 0, 0.26)"
              />
            </Button>
          </Grid>
        </Grid>
        <Grid container alignItems="center" justify="center">
          <Grid item xs={10}>
            <hr className="greyLine" />
          </Grid>
        </Grid>
        <Grid container alignItems="center" justify="center">
          <Grid item>
            <p style={{ marginBottom: '100px' }}>
              {I18n.t('landing.allRightsReserved')}
            </p>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default withRouter(LandingPage);
