import React from 'react';
import Grid from '@material-ui/core/Grid';
import './BulletPoints.css';

export default function BulletPoints({ text }) {
  return (
    <>
      <Grid
        item
        xs={2}
        sm={1}
        style={{
          marginTop: '20px',
          marginBottom: '20px',
        }}
      >
        <svg
          className="checkmark"
          x="0px"
          y="0px"
          viewBox="0 0 24 24"
          data-type="glyph"
          data-name="check-circle-07"
        >
          <g fill="currentColor">
            <path
              data-color="color-2"
              fill="currentColor"
              d="M20.4,8.4c-0.5,0.1-0.8,0.7-0.7,1.2c0.2,0.8,0.3,1.6,0.3,2.4c0,5-4,9-9,9s-9-4-9-9s4-9,9-9 c1.7,0,3.3,0.5,4.7,1.3c0.5,0.3,1.1,0.1,1.4-0.3c0.3-0.5,0.1-1.1-0.3-1.4C15,1.6,13,1,11,1C4.9,1,0,5.9,0,12s4.9,11,11,11 s11-4.9,11-11c0-1-0.1-2-0.4-2.9C21.5,8.6,20.9,8.3,20.4,8.4z"
            ></path>{' '}
            <path
              fill="currentColor"
              d="M22.7,2.3c-0.4-0.4-1-0.4-1.4,0L11,12.6L7.7,9.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l4,4 c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l11-11C23.1,3.3,23.1,2.7,22.7,2.3z"
            ></path>
          </g>
        </svg>
      </Grid>
      <Grid item xs={10} sm={11}>
        <b>{text}</b>
      </Grid>
    </>
  );
}
