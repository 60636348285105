import React from 'react';
import { IconFlagFR, IconFlagUS } from 'material-ui-flags';

import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

import I18n, { toggle } from '../i18n/I18n';

export default function LanguageButtons({ updateLanguage }) {
  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      style={{ marginBottom: '40px' }}
    >
      <Grid item>
        {I18n.locale === 'en' && (
          <Link
            href="#"
            onClick={(event) => {
              event.preventDefault();
              toggle('fr');
              updateLanguage();
            }}
            style={{
              fontSize: '20px',
            }}
          >
            Français
            <IconFlagFR
              style={{
                height: '100%',
                position: 'relative',
                top: '2px',
                marginLeft: '6px',
              }}
            />
          </Link>
        )}
        {I18n.locale === 'fr' && (
          <Link
            href="#"
            onClick={(event) => {
              event.preventDefault();
              toggle('en');
              updateLanguage();
            }}
            style={{
              fontSize: '20px',
            }}
          >
            English
            <IconFlagUS
              style={{
                height: '100%',
                position: 'relative',
                top: '2px',
                marginLeft: '6px',
              }}
            />
          </Link>
        )}
      </Grid>
    </Grid>
  );
}
